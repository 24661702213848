import {
  ArdeschirBabekanCard,
  ArdeschirLeBonCard,
  ArdeschirSonOfSchirouiCard,
  AschkanidesCard,
  AzermidokhtCard,
  BahmanCard,
  BahramBahramianCard,
  BahramGourCard,
  BahramSonOfBahramCard,
  BahramSonOfOrmuzdCard,
  BahramSonOfSchapourCard,
  BalaschCard,
  DarabCard,
  DaraCard,
  DjemschidCard,
  FarrukhzadCard,
  FeridounCard,
  GuerschaspCard,
  GurazCard,
  GuschtaspCard,
  HomaiCard,
  HormuzCard,
  HormuzdCard,
  HouschengCard,
  IskenderCard,
  KaioumorsCard,
  KaousCard,
  KhosrouCard,
  KhosrouParvizCard,
  KobadCard,
  KobadSonOfParvizCard,
  KobadSonOfPirouzCard,
  LohraspCard,
  MinoutchehrCard,
  NersiCard,
  NewderCard,
  NouschirwanCard,
  OrmuzdCard,
  OrmuzdSonOfNersiCard,
  PirouzCard,
  PourandokhtCard,
  SchapourCard,
  SchapourDhoulAktafCard,
  SchapourSonOfSchapourCard,
  ThahmourasCard,
  YezdegirdCard,
  YezdeguerdCard,
  YezdeguerdSonOfBahramCard,
  ZewCard,
  ZohakCard,
} from '.';
import React from 'react';

export const ReignCards: React.FC = () => {
  const orderedCards = [
    KaioumorsCard,
    HouschengCard,
    ThahmourasCard,
    DjemschidCard,
    ZohakCard,
    FeridounCard,
    MinoutchehrCard,
    NewderCard,
    ZewCard,
    GuerschaspCard,
    KobadCard,
    KaousCard,
    KhosrouCard,
    LohraspCard,
    GuschtaspCard,
    BahmanCard,
    HomaiCard,
    DarabCard,
    DaraCard,
    IskenderCard,
    AschkanidesCard,
    ArdeschirBabekanCard,
    SchapourCard,
    OrmuzdCard,
    BahramSonOfOrmuzdCard,
    BahramSonOfBahramCard,
    BahramBahramianCard,
    NersiCard,
    OrmuzdSonOfNersiCard,
    SchapourDhoulAktafCard,
    ArdeschirLeBonCard,
    SchapourSonOfSchapourCard,
    BahramSonOfSchapourCard,
    YezdeguerdCard,
    BahramGourCard,
    YezdeguerdSonOfBahramCard,
    HormuzCard,
    PirouzCard,
    BalaschCard,
    KobadSonOfPirouzCard,
    NouschirwanCard,
    HormuzdCard,
    KhosrouParvizCard,
    KobadSonOfParvizCard,
    ArdeschirSonOfSchirouiCard,
    GurazCard,
    PourandokhtCard,
    AzermidokhtCard,
    FarrukhzadCard,
    YezdegirdCard,
  ];
  return (
    <div className="row">
      {orderedCards.map((Card, index) => (
        <div className="col-sm-6 col-lg-3" key={index}>
          <Card />
        </div>
      ))}
    </div>
  );
};
